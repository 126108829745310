.card {
  .card-image {
    img {
      height: auto;
    }
  }
  .card-title {
    font-size: 1.25rem;
    font-weight: 400;
  }
  .card-title-suffix {
    font-size: 1rem;
    color: $off-grey;
  }
  .card-content {
    position: relative;
    min-height: 80px;

    .card-title {
      font-size: 1.25rem;
      line-height: 1.3;
    }
  }
  .card-reveal {
    .card-title {
      line-height: 1.2;
    }

    p {
      margin-top: 8px;
    }

    .view-display-id-block_1 {
      ul {
        margin: 0.75em 0;

        li {
          margin: 0 0 0.25em 0;

          a {
            color: $primary-color;
          }
        }
      }
    }

    .menu-list-learner,
    .menu-list-pricing {
      margin-left: .5rem;

      svg {
        height: 16px;
        margin-bottom: -3px;
      }
    }

    .menu-list-learner {
      svg {
        fill: color('yellow', 'darken-2');
      }
    }

    .menu-list-pricing {
      svg {
        fill: color('red', 'accent-4');
      }
    }
  }
  .card-action {
    text-align: center;

    a {
      margin-right: 0 !important;
      color: $primary-color !important;
    }
  }
}

.unit-card-wrapper {
  position: relative;

  .btn-floating {
    &.halfway-fab {
      bottom: 36px;
    }
  }
}

.node-unit {
  .unit-flags {
    position: absolute;
    top: 18px;
    right: 16px;

    .learner-flag {
      svg {
        height: 16px;
        fill: color('yellow', 'darken-2');
        margin-bottom: -3px;
      }
    }

    .finance-flag {
      margin-left: 5px;
      color: color('red', 'accent-4');
      svg {
        height: 16px;
        fill: color('red', 'accent-4');
        margin-bottom: -3px;
      }
    }
  }
  .card-content {
    text-align: center;

    .card-title {
      margin-bottom: 0;

      &.truncate {
        &:not(:last-child) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}

.view-display-id-panel_pane_1 {
  .card {
    box-shadow: none;

    .card-content {
      background: rgba(160, 160, 160, 0.2);
    }
  }
}

.view-display-id-panel_pane_2 {
  .card {
    box-shadow: none;
  }
}

.card-carousel,
.cvp-carousel {
  margin: 0 -20px;
  width: calc(100% + 40px);
  overflow: hidden;
}
