.materialize-red {
  background-color: #e51c23 !important;
}

.materialize-red-text {
  color: #e51c23 !important;
}

.materialize-red.lighten-5, .unit-calltoaction-wrapper .materialize-red.unit-enquire {
  background-color: #fdeaeb !important;
}

.materialize-red-text.text-lighten-5 {
  color: #fdeaeb !important;
}

.materialize-red.lighten-4 {
  background-color: #f8c1c3 !important;
}

.materialize-red-text.text-lighten-4 {
  color: #f8c1c3 !important;
}

.materialize-red.lighten-3, .materialize-red.page-title, .materialize-red.unit-calltoaction-wrapper {
  background-color: #f3989b !important;
}

.materialize-red-text.text-lighten-3 {
  color: #f3989b !important;
}

.materialize-red.lighten-2 {
  background-color: #ee6e73 !important;
}

.materialize-red-text.text-lighten-2 {
  color: #ee6e73 !important;
}

.materialize-red.lighten-1 {
  background-color: #ea454b !important;
}

.materialize-red-text.text-lighten-1 {
  color: #ea454b !important;
}

.materialize-red.darken-1 {
  background-color: #d0181e !important;
}

.materialize-red-text.text-darken-1 {
  color: #d0181e !important;
}

.materialize-red.darken-2 {
  background-color: #b9151b !important;
}

.materialize-red-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.materialize-red-text {
  color: #b9151b !important;
}

.materialize-red.darken-3, .materialize-red.page-footer {
  background-color: #a21318 !important;
}

.materialize-red-text.text-darken-3 {
  color: #a21318 !important;
}

.materialize-red.darken-4 {
  background-color: #8b1014 !important;
}

.materialize-red-text.text-darken-4 {
  color: #8b1014 !important;
}

.red {
  background-color: #F44336 !important;
}

.red-text {
  color: #F44336 !important;
}

.red.lighten-5, .unit-calltoaction-wrapper .red.unit-enquire {
  background-color: #FFEBEE !important;
}

.red-text.text-lighten-5 {
  color: #FFEBEE !important;
}

.red.lighten-4 {
  background-color: #FFCDD2 !important;
}

.red-text.text-lighten-4 {
  color: #FFCDD2 !important;
}

.red.lighten-3, .red.page-title, .red.unit-calltoaction-wrapper {
  background-color: #EF9A9A !important;
}

.red-text.text-lighten-3 {
  color: #EF9A9A !important;
}

.red.lighten-2 {
  background-color: #E57373 !important;
}

.red-text.text-lighten-2 {
  color: #E57373 !important;
}

.red.lighten-1 {
  background-color: #EF5350 !important;
}

.red-text.text-lighten-1 {
  color: #EF5350 !important;
}

.red.darken-1 {
  background-color: #E53935 !important;
}

.red-text.text-darken-1 {
  color: #E53935 !important;
}

.red.darken-2 {
  background-color: #D32F2F !important;
}

.red-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.red-text {
  color: #D32F2F !important;
}

.red.darken-3, .red.page-footer {
  background-color: #C62828 !important;
}

.red-text.text-darken-3 {
  color: #C62828 !important;
}

.red.darken-4 {
  background-color: #B71C1C !important;
}

.red-text.text-darken-4 {
  color: #B71C1C !important;
}

.red.accent-1 {
  background-color: #FF8A80 !important;
}

.red-text.text-accent-1 {
  color: #FF8A80 !important;
}

.red.accent-2 {
  background-color: #FF5252 !important;
}

.red-text.text-accent-2 {
  color: #FF5252 !important;
}

.red.accent-3 {
  background-color: #FF1744 !important;
}

.red-text.text-accent-3 {
  color: #FF1744 !important;
}

.red.accent-4 {
  background-color: #D50000 !important;
}

.red-text.text-accent-4 {
  color: #D50000 !important;
}

.pink {
  background-color: #e91e63 !important;
}

.pink-text {
  color: #e91e63 !important;
}

.pink.lighten-5, .unit-calltoaction-wrapper .pink.unit-enquire {
  background-color: #fce4ec !important;
}

.pink-text.text-lighten-5 {
  color: #fce4ec !important;
}

.pink.lighten-4 {
  background-color: #f8bbd0 !important;
}

.pink-text.text-lighten-4 {
  color: #f8bbd0 !important;
}

.pink.lighten-3, .pink.page-title, .pink.unit-calltoaction-wrapper {
  background-color: #f48fb1 !important;
}

.pink-text.text-lighten-3 {
  color: #f48fb1 !important;
}

.pink.lighten-2 {
  background-color: #f06292 !important;
}

.pink-text.text-lighten-2 {
  color: #f06292 !important;
}

.pink.lighten-1 {
  background-color: #ec407a !important;
}

.pink-text.text-lighten-1 {
  color: #ec407a !important;
}

.pink.darken-1 {
  background-color: #d81b60 !important;
}

.pink-text.text-darken-1 {
  color: #d81b60 !important;
}

.pink.darken-2 {
  background-color: #c2185b !important;
}

.pink-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.pink-text {
  color: #c2185b !important;
}

.pink.darken-3, .pink.page-footer {
  background-color: #ad1457 !important;
}

.pink-text.text-darken-3 {
  color: #ad1457 !important;
}

.pink.darken-4 {
  background-color: #880e4f !important;
}

.pink-text.text-darken-4 {
  color: #880e4f !important;
}

.pink.accent-1 {
  background-color: #ff80ab !important;
}

.pink-text.text-accent-1 {
  color: #ff80ab !important;
}

.pink.accent-2 {
  background-color: #ff4081 !important;
}

.pink-text.text-accent-2 {
  color: #ff4081 !important;
}

.pink.accent-3 {
  background-color: #f50057 !important;
}

.pink-text.text-accent-3 {
  color: #f50057 !important;
}

.pink.accent-4 {
  background-color: #c51162 !important;
}

.pink-text.text-accent-4 {
  color: #c51162 !important;
}

.purple {
  background-color: #9c27b0 !important;
}

.purple-text {
  color: #9c27b0 !important;
}

.purple.lighten-5, .unit-calltoaction-wrapper .purple.unit-enquire {
  background-color: #f3e5f5 !important;
}

.purple-text.text-lighten-5 {
  color: #f3e5f5 !important;
}

.purple.lighten-4 {
  background-color: #e1bee7 !important;
}

.purple-text.text-lighten-4 {
  color: #e1bee7 !important;
}

.purple.lighten-3, .purple.page-title, .purple.unit-calltoaction-wrapper {
  background-color: #ce93d8 !important;
}

.purple-text.text-lighten-3 {
  color: #ce93d8 !important;
}

.purple.lighten-2 {
  background-color: #ba68c8 !important;
}

.purple-text.text-lighten-2 {
  color: #ba68c8 !important;
}

.purple.lighten-1 {
  background-color: #ab47bc !important;
}

.purple-text.text-lighten-1 {
  color: #ab47bc !important;
}

.purple.darken-1 {
  background-color: #8e24aa !important;
}

.purple-text.text-darken-1 {
  color: #8e24aa !important;
}

.purple.darken-2 {
  background-color: #7b1fa2 !important;
}

.purple-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.purple-text {
  color: #7b1fa2 !important;
}

.purple.darken-3, .purple.page-footer {
  background-color: #6a1b9a !important;
}

.purple-text.text-darken-3 {
  color: #6a1b9a !important;
}

.purple.darken-4 {
  background-color: #4a148c !important;
}

.purple-text.text-darken-4 {
  color: #4a148c !important;
}

.purple.accent-1 {
  background-color: #ea80fc !important;
}

.purple-text.text-accent-1 {
  color: #ea80fc !important;
}

.purple.accent-2 {
  background-color: #e040fb !important;
}

.purple-text.text-accent-2 {
  color: #e040fb !important;
}

.purple.accent-3 {
  background-color: #d500f9 !important;
}

.purple-text.text-accent-3 {
  color: #d500f9 !important;
}

.purple.accent-4 {
  background-color: #aa00ff !important;
}

.purple-text.text-accent-4 {
  color: #aa00ff !important;
}

.deep-purple {
  background-color: #673ab7 !important;
}

.deep-purple-text {
  color: #673ab7 !important;
}

.deep-purple.lighten-5, .unit-calltoaction-wrapper .deep-purple.unit-enquire {
  background-color: #ede7f6 !important;
}

.deep-purple-text.text-lighten-5 {
  color: #ede7f6 !important;
}

.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
}

.deep-purple-text.text-lighten-4 {
  color: #d1c4e9 !important;
}

.deep-purple.lighten-3, .deep-purple.page-title, .deep-purple.unit-calltoaction-wrapper {
  background-color: #b39ddb !important;
}

.deep-purple-text.text-lighten-3 {
  color: #b39ddb !important;
}

.deep-purple.lighten-2 {
  background-color: #9575cd !important;
}

.deep-purple-text.text-lighten-2 {
  color: #9575cd !important;
}

.deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
}

.deep-purple-text.text-lighten-1 {
  color: #7e57c2 !important;
}

.deep-purple.darken-1 {
  background-color: #5e35b1 !important;
}

.deep-purple-text.text-darken-1 {
  color: #5e35b1 !important;
}

.deep-purple.darken-2 {
  background-color: #512da8 !important;
}

.deep-purple-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.deep-purple-text {
  color: #512da8 !important;
}

.deep-purple.darken-3, .deep-purple.page-footer {
  background-color: #4527a0 !important;
}

.deep-purple-text.text-darken-3 {
  color: #4527a0 !important;
}

.deep-purple.darken-4 {
  background-color: #311b92 !important;
}

.deep-purple-text.text-darken-4 {
  color: #311b92 !important;
}

.deep-purple.accent-1 {
  background-color: #b388ff !important;
}

.deep-purple-text.text-accent-1 {
  color: #b388ff !important;
}

.deep-purple.accent-2 {
  background-color: #7c4dff !important;
}

.deep-purple-text.text-accent-2 {
  color: #7c4dff !important;
}

.deep-purple.accent-3 {
  background-color: #651fff !important;
}

.deep-purple-text.text-accent-3 {
  color: #651fff !important;
}

.deep-purple.accent-4 {
  background-color: #6200ea !important;
}

.deep-purple-text.text-accent-4 {
  color: #6200ea !important;
}

.indigo {
  background-color: #3f51b5 !important;
}

.indigo-text {
  color: #3f51b5 !important;
}

.indigo.lighten-5, .unit-calltoaction-wrapper .indigo.unit-enquire {
  background-color: #e8eaf6 !important;
}

.indigo-text.text-lighten-5 {
  color: #e8eaf6 !important;
}

.indigo.lighten-4 {
  background-color: #c5cae9 !important;
}

.indigo-text.text-lighten-4 {
  color: #c5cae9 !important;
}

.indigo.lighten-3, .indigo.page-title, .indigo.unit-calltoaction-wrapper {
  background-color: #9fa8da !important;
}

.indigo-text.text-lighten-3 {
  color: #9fa8da !important;
}

.indigo.lighten-2 {
  background-color: #7986cb !important;
}

.indigo-text.text-lighten-2 {
  color: #7986cb !important;
}

.indigo.lighten-1 {
  background-color: #5c6bc0 !important;
}

.indigo-text.text-lighten-1 {
  color: #5c6bc0 !important;
}

.indigo.darken-1 {
  background-color: #3949ab !important;
}

.indigo-text.text-darken-1 {
  color: #3949ab !important;
}

.indigo.darken-2 {
  background-color: #303f9f !important;
}

.indigo-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.indigo-text {
  color: #303f9f !important;
}

.indigo.darken-3, .indigo.page-footer {
  background-color: #283593 !important;
}

.indigo-text.text-darken-3 {
  color: #283593 !important;
}

.indigo.darken-4 {
  background-color: #1a237e !important;
}

.indigo-text.text-darken-4 {
  color: #1a237e !important;
}

.indigo.accent-1 {
  background-color: #8c9eff !important;
}

.indigo-text.text-accent-1 {
  color: #8c9eff !important;
}

.indigo.accent-2 {
  background-color: #536dfe !important;
}

.indigo-text.text-accent-2 {
  color: #536dfe !important;
}

.indigo.accent-3 {
  background-color: #3d5afe !important;
}

.indigo-text.text-accent-3 {
  color: #3d5afe !important;
}

.indigo.accent-4 {
  background-color: #304ffe !important;
}

.indigo-text.text-accent-4 {
  color: #304ffe !important;
}

.blue {
  background-color: #2196F3 !important;
}

.blue-text {
  color: #2196F3 !important;
}

.blue.lighten-5, .unit-calltoaction-wrapper .blue.unit-enquire {
  background-color: #E3F2FD !important;
}

.blue-text.text-lighten-5 {
  color: #E3F2FD !important;
}

.blue.lighten-4 {
  background-color: #BBDEFB !important;
}

.blue-text.text-lighten-4 {
  color: #BBDEFB !important;
}

.blue.lighten-3, .blue.page-title, .blue.unit-calltoaction-wrapper {
  background-color: #90CAF9 !important;
}

.blue-text.text-lighten-3 {
  color: #90CAF9 !important;
}

.blue.lighten-2 {
  background-color: #64B5F6 !important;
}

.blue-text.text-lighten-2 {
  color: #64B5F6 !important;
}

.blue.lighten-1 {
  background-color: #42A5F5 !important;
}

.blue-text.text-lighten-1 {
  color: #42A5F5 !important;
}

.blue.darken-1 {
  background-color: #1E88E5 !important;
}

.blue-text.text-darken-1 {
  color: #1E88E5 !important;
}

.blue.darken-2 {
  background-color: #1976D2 !important;
}

.blue-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.blue-text {
  color: #1976D2 !important;
}

.blue.darken-3, .blue.page-footer {
  background-color: #1565C0 !important;
}

.blue-text.text-darken-3 {
  color: #1565C0 !important;
}

.blue.darken-4 {
  background-color: #0D47A1 !important;
}

.blue-text.text-darken-4 {
  color: #0D47A1 !important;
}

.blue.accent-1 {
  background-color: #82B1FF !important;
}

.blue-text.text-accent-1 {
  color: #82B1FF !important;
}

.blue.accent-2 {
  background-color: #448AFF !important;
}

.blue-text.text-accent-2 {
  color: #448AFF !important;
}

.blue.accent-3 {
  background-color: #2979FF !important;
}

.blue-text.text-accent-3 {
  color: #2979FF !important;
}

.blue.accent-4 {
  background-color: #2962FF !important;
}

.blue-text.text-accent-4 {
  color: #2962FF !important;
}

.light-blue {
  background-color: #03a9f4 !important;
}

.light-blue-text {
  color: #03a9f4 !important;
}

.light-blue.lighten-5, .unit-calltoaction-wrapper .light-blue.unit-enquire {
  background-color: #e1f5fe !important;
}

.light-blue-text.text-lighten-5 {
  color: #e1f5fe !important;
}

.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
}

.light-blue-text.text-lighten-4 {
  color: #b3e5fc !important;
}

.light-blue.lighten-3, .light-blue.page-title, .light-blue.unit-calltoaction-wrapper {
  background-color: #81d4fa !important;
}

.light-blue-text.text-lighten-3 {
  color: #81d4fa !important;
}

.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
}

.light-blue-text.text-lighten-2 {
  color: #4fc3f7 !important;
}

.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
}

.light-blue-text.text-lighten-1 {
  color: #29b6f6 !important;
}

.light-blue.darken-1 {
  background-color: #039be5 !important;
}

.light-blue-text.text-darken-1 {
  color: #039be5 !important;
}

.light-blue.darken-2 {
  background-color: #0288d1 !important;
}

.light-blue-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.light-blue-text {
  color: #0288d1 !important;
}

.light-blue.darken-3, .light-blue.page-footer {
  background-color: #0277bd !important;
}

.light-blue-text.text-darken-3 {
  color: #0277bd !important;
}

.light-blue.darken-4 {
  background-color: #01579b !important;
}

.light-blue-text.text-darken-4 {
  color: #01579b !important;
}

.light-blue.accent-1 {
  background-color: #80d8ff !important;
}

.light-blue-text.text-accent-1 {
  color: #80d8ff !important;
}

.light-blue.accent-2 {
  background-color: #40c4ff !important;
}

.light-blue-text.text-accent-2 {
  color: #40c4ff !important;
}

.light-blue.accent-3 {
  background-color: #00b0ff !important;
}

.light-blue-text.text-accent-3 {
  color: #00b0ff !important;
}

.light-blue.accent-4 {
  background-color: #0091ea !important;
}

.light-blue-text.text-accent-4 {
  color: #0091ea !important;
}

.cyan {
  background-color: #00bcd4 !important;
}

.cyan-text {
  color: #00bcd4 !important;
}

.cyan.lighten-5, .unit-calltoaction-wrapper .cyan.unit-enquire {
  background-color: #e0f7fa !important;
}

.cyan-text.text-lighten-5 {
  color: #e0f7fa !important;
}

.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
}

.cyan-text.text-lighten-4 {
  color: #b2ebf2 !important;
}

.cyan.lighten-3, .cyan.page-title, .cyan.unit-calltoaction-wrapper {
  background-color: #80deea !important;
}

.cyan-text.text-lighten-3 {
  color: #80deea !important;
}

.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
}

.cyan-text.text-lighten-2 {
  color: #4dd0e1 !important;
}

.cyan.lighten-1 {
  background-color: #26c6da !important;
}

.cyan-text.text-lighten-1 {
  color: #26c6da !important;
}

.cyan.darken-1 {
  background-color: #00acc1 !important;
}

.cyan-text.text-darken-1 {
  color: #00acc1 !important;
}

.cyan.darken-2 {
  background-color: #0097a7 !important;
}

.cyan-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.cyan-text {
  color: #0097a7 !important;
}

.cyan.darken-3, .cyan.page-footer {
  background-color: #00838f !important;
}

.cyan-text.text-darken-3 {
  color: #00838f !important;
}

.cyan.darken-4 {
  background-color: #006064 !important;
}

.cyan-text.text-darken-4 {
  color: #006064 !important;
}

.cyan.accent-1 {
  background-color: #84ffff !important;
}

.cyan-text.text-accent-1 {
  color: #84ffff !important;
}

.cyan.accent-2 {
  background-color: #18ffff !important;
}

.cyan-text.text-accent-2 {
  color: #18ffff !important;
}

.cyan.accent-3 {
  background-color: #00e5ff !important;
}

.cyan-text.text-accent-3 {
  color: #00e5ff !important;
}

.cyan.accent-4 {
  background-color: #00b8d4 !important;
}

.cyan-text.text-accent-4 {
  color: #00b8d4 !important;
}

.teal {
  background-color: #009688 !important;
}

.teal-text {
  color: #009688 !important;
}

.teal.lighten-5, .unit-calltoaction-wrapper .teal.unit-enquire {
  background-color: #e0f2f1 !important;
}

.teal-text.text-lighten-5 {
  color: #e0f2f1 !important;
}

.teal.lighten-4 {
  background-color: #b2dfdb !important;
}

.teal-text.text-lighten-4 {
  color: #b2dfdb !important;
}

.teal.lighten-3, .teal.page-title, .teal.unit-calltoaction-wrapper {
  background-color: #80cbc4 !important;
}

.teal-text.text-lighten-3 {
  color: #80cbc4 !important;
}

.teal.lighten-2 {
  background-color: #4db6ac !important;
}

.teal-text.text-lighten-2 {
  color: #4db6ac !important;
}

.teal.lighten-1 {
  background-color: #26a69a !important;
}

.teal-text.text-lighten-1 {
  color: #26a69a !important;
}

.teal.darken-1 {
  background-color: #00897b !important;
}

.teal-text.text-darken-1 {
  color: #00897b !important;
}

.teal.darken-2 {
  background-color: #00796b !important;
}

.teal-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.teal-text {
  color: #00796b !important;
}

.teal.darken-3, .teal.page-footer {
  background-color: #00695c !important;
}

.teal-text.text-darken-3 {
  color: #00695c !important;
}

.teal.darken-4 {
  background-color: #004d40 !important;
}

.teal-text.text-darken-4 {
  color: #004d40 !important;
}

.teal.accent-1 {
  background-color: #a7ffeb !important;
}

.teal-text.text-accent-1 {
  color: #a7ffeb !important;
}

.teal.accent-2 {
  background-color: #64ffda !important;
}

.teal-text.text-accent-2 {
  color: #64ffda !important;
}

.teal.accent-3 {
  background-color: #1de9b6 !important;
}

.teal-text.text-accent-3 {
  color: #1de9b6 !important;
}

.teal.accent-4 {
  background-color: #00bfa5 !important;
}

.teal-text.text-accent-4 {
  color: #00bfa5 !important;
}

.green {
  background-color: #4CAF50 !important;
}

.green-text {
  color: #4CAF50 !important;
}

.green.lighten-5, .unit-calltoaction-wrapper .green.unit-enquire {
  background-color: #E8F5E9 !important;
}

.green-text.text-lighten-5 {
  color: #E8F5E9 !important;
}

.green.lighten-4 {
  background-color: #C8E6C9 !important;
}

.green-text.text-lighten-4 {
  color: #C8E6C9 !important;
}

.green.lighten-3, .green.page-title, .green.unit-calltoaction-wrapper {
  background-color: #A5D6A7 !important;
}

.green-text.text-lighten-3 {
  color: #A5D6A7 !important;
}

.green.lighten-2 {
  background-color: #81C784 !important;
}

.green-text.text-lighten-2 {
  color: #81C784 !important;
}

.green.lighten-1 {
  background-color: #66BB6A !important;
}

.green-text.text-lighten-1 {
  color: #66BB6A !important;
}

.green.darken-1 {
  background-color: #43A047 !important;
}

.green-text.text-darken-1 {
  color: #43A047 !important;
}

.green.darken-2 {
  background-color: #388E3C !important;
}

.green-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.green-text {
  color: #388E3C !important;
}

.green.darken-3, .green.page-footer {
  background-color: #2E7D32 !important;
}

.green-text.text-darken-3 {
  color: #2E7D32 !important;
}

.green.darken-4 {
  background-color: #1B5E20 !important;
}

.green-text.text-darken-4 {
  color: #1B5E20 !important;
}

.green.accent-1 {
  background-color: #B9F6CA !important;
}

.green-text.text-accent-1 {
  color: #B9F6CA !important;
}

.green.accent-2 {
  background-color: #69F0AE !important;
}

.green-text.text-accent-2 {
  color: #69F0AE !important;
}

.green.accent-3 {
  background-color: #00E676 !important;
}

.green-text.text-accent-3 {
  color: #00E676 !important;
}

.green.accent-4 {
  background-color: #00C853 !important;
}

.green-text.text-accent-4 {
  color: #00C853 !important;
}

.light-green {
  background-color: #8bc34a !important;
}

.light-green-text {
  color: #8bc34a !important;
}

.light-green.lighten-5, .unit-calltoaction-wrapper .light-green.unit-enquire {
  background-color: #f1f8e9 !important;
}

.light-green-text.text-lighten-5 {
  color: #f1f8e9 !important;
}

.light-green.lighten-4 {
  background-color: #dcedc8 !important;
}

.light-green-text.text-lighten-4 {
  color: #dcedc8 !important;
}

.light-green.lighten-3, .light-green.page-title, .light-green.unit-calltoaction-wrapper {
  background-color: #c5e1a5 !important;
}

.light-green-text.text-lighten-3 {
  color: #c5e1a5 !important;
}

.light-green.lighten-2 {
  background-color: #aed581 !important;
}

.light-green-text.text-lighten-2 {
  color: #aed581 !important;
}

.light-green.lighten-1 {
  background-color: #9ccc65 !important;
}

.light-green-text.text-lighten-1 {
  color: #9ccc65 !important;
}

.light-green.darken-1 {
  background-color: #7cb342 !important;
}

.light-green-text.text-darken-1 {
  color: #7cb342 !important;
}

.light-green.darken-2 {
  background-color: #689f38 !important;
}

.light-green-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.light-green-text {
  color: #689f38 !important;
}

.light-green.darken-3, .light-green.page-footer {
  background-color: #558b2f !important;
}

.light-green-text.text-darken-3 {
  color: #558b2f !important;
}

.light-green.darken-4 {
  background-color: #33691e !important;
}

.light-green-text.text-darken-4 {
  color: #33691e !important;
}

.light-green.accent-1 {
  background-color: #ccff90 !important;
}

.light-green-text.text-accent-1 {
  color: #ccff90 !important;
}

.light-green.accent-2 {
  background-color: #b2ff59 !important;
}

.light-green-text.text-accent-2 {
  color: #b2ff59 !important;
}

.light-green.accent-3 {
  background-color: #76ff03 !important;
}

.light-green-text.text-accent-3 {
  color: #76ff03 !important;
}

.light-green.accent-4 {
  background-color: #64dd17 !important;
}

.light-green-text.text-accent-4 {
  color: #64dd17 !important;
}

.lime {
  background-color: #cddc39 !important;
}

.lime-text {
  color: #cddc39 !important;
}

.lime.lighten-5, .unit-calltoaction-wrapper .lime.unit-enquire {
  background-color: #f9fbe7 !important;
}

.lime-text.text-lighten-5 {
  color: #f9fbe7 !important;
}

.lime.lighten-4 {
  background-color: #f0f4c3 !important;
}

.lime-text.text-lighten-4 {
  color: #f0f4c3 !important;
}

.lime.lighten-3, .lime.page-title, .lime.unit-calltoaction-wrapper {
  background-color: #e6ee9c !important;
}

.lime-text.text-lighten-3 {
  color: #e6ee9c !important;
}

.lime.lighten-2 {
  background-color: #dce775 !important;
}

.lime-text.text-lighten-2 {
  color: #dce775 !important;
}

.lime.lighten-1 {
  background-color: #d4e157 !important;
}

.lime-text.text-lighten-1 {
  color: #d4e157 !important;
}

.lime.darken-1 {
  background-color: #c0ca33 !important;
}

.lime-text.text-darken-1 {
  color: #c0ca33 !important;
}

.lime.darken-2 {
  background-color: #afb42b !important;
}

.lime-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.lime-text {
  color: #afb42b !important;
}

.lime.darken-3, .lime.page-footer {
  background-color: #9e9d24 !important;
}

.lime-text.text-darken-3 {
  color: #9e9d24 !important;
}

.lime.darken-4 {
  background-color: #827717 !important;
}

.lime-text.text-darken-4 {
  color: #827717 !important;
}

.lime.accent-1 {
  background-color: #f4ff81 !important;
}

.lime-text.text-accent-1 {
  color: #f4ff81 !important;
}

.lime.accent-2 {
  background-color: #eeff41 !important;
}

.lime-text.text-accent-2 {
  color: #eeff41 !important;
}

.lime.accent-3 {
  background-color: #c6ff00 !important;
}

.lime-text.text-accent-3 {
  color: #c6ff00 !important;
}

.lime.accent-4 {
  background-color: #aeea00 !important;
}

.lime-text.text-accent-4 {
  color: #aeea00 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}

.yellow-text {
  color: #ffeb3b !important;
}

.yellow.lighten-5, .unit-calltoaction-wrapper .yellow.unit-enquire {
  background-color: #fffde7 !important;
}

.yellow-text.text-lighten-5 {
  color: #fffde7 !important;
}

.yellow.lighten-4 {
  background-color: #fff9c4 !important;
}

.yellow-text.text-lighten-4 {
  color: #fff9c4 !important;
}

.yellow.lighten-3, .yellow.page-title, .yellow.unit-calltoaction-wrapper {
  background-color: #fff59d !important;
}

.yellow-text.text-lighten-3 {
  color: #fff59d !important;
}

.yellow.lighten-2 {
  background-color: #fff176 !important;
}

.yellow-text.text-lighten-2 {
  color: #fff176 !important;
}

.yellow.lighten-1 {
  background-color: #ffee58 !important;
}

.yellow-text.text-lighten-1 {
  color: #ffee58 !important;
}

.yellow.darken-1 {
  background-color: #fdd835 !important;
}

.yellow-text.text-darken-1 {
  color: #fdd835 !important;
}

.yellow.darken-2 {
  background-color: #fbc02d !important;
}

.yellow-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.yellow-text {
  color: #fbc02d !important;
}

.yellow.darken-3, .yellow.page-footer {
  background-color: #f9a825 !important;
}

.yellow-text.text-darken-3 {
  color: #f9a825 !important;
}

.yellow.darken-4 {
  background-color: #f57f17 !important;
}

.yellow-text.text-darken-4 {
  color: #f57f17 !important;
}

.yellow.accent-1 {
  background-color: #ffff8d !important;
}

.yellow-text.text-accent-1 {
  color: #ffff8d !important;
}

.yellow.accent-2 {
  background-color: #ffff00 !important;
}

.yellow-text.text-accent-2 {
  color: #ffff00 !important;
}

.yellow.accent-3 {
  background-color: #ffea00 !important;
}

.yellow-text.text-accent-3 {
  color: #ffea00 !important;
}

.yellow.accent-4 {
  background-color: #ffd600 !important;
}

.yellow-text.text-accent-4 {
  color: #ffd600 !important;
}

.amber {
  background-color: #ffc107 !important;
}

.amber-text {
  color: #ffc107 !important;
}

.amber.lighten-5, .unit-calltoaction-wrapper .amber.unit-enquire {
  background-color: #fff8e1 !important;
}

.amber-text.text-lighten-5 {
  color: #fff8e1 !important;
}

.amber.lighten-4 {
  background-color: #ffecb3 !important;
}

.amber-text.text-lighten-4 {
  color: #ffecb3 !important;
}

.amber.lighten-3, .amber.page-title, .amber.unit-calltoaction-wrapper {
  background-color: #ffe082 !important;
}

.amber-text.text-lighten-3 {
  color: #ffe082 !important;
}

.amber.lighten-2 {
  background-color: #ffd54f !important;
}

.amber-text.text-lighten-2 {
  color: #ffd54f !important;
}

.amber.lighten-1 {
  background-color: #ffca28 !important;
}

.amber-text.text-lighten-1 {
  color: #ffca28 !important;
}

.amber.darken-1 {
  background-color: #ffb300 !important;
}

.amber-text.text-darken-1 {
  color: #ffb300 !important;
}

.amber.darken-2 {
  background-color: #ffa000 !important;
}

.amber-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.amber-text {
  color: #ffa000 !important;
}

.amber.darken-3, .amber.page-footer {
  background-color: #ff8f00 !important;
}

.amber-text.text-darken-3 {
  color: #ff8f00 !important;
}

.amber.darken-4 {
  background-color: #ff6f00 !important;
}

.amber-text.text-darken-4 {
  color: #ff6f00 !important;
}

.amber.accent-1 {
  background-color: #ffe57f !important;
}

.amber-text.text-accent-1 {
  color: #ffe57f !important;
}

.amber.accent-2 {
  background-color: #ffd740 !important;
}

.amber-text.text-accent-2 {
  color: #ffd740 !important;
}

.amber.accent-3 {
  background-color: #ffc400 !important;
}

.amber-text.text-accent-3 {
  color: #ffc400 !important;
}

.amber.accent-4 {
  background-color: #ffab00 !important;
}

.amber-text.text-accent-4 {
  color: #ffab00 !important;
}

.orange {
  background-color: #ff9800 !important;
}

.orange-text {
  color: #ff9800 !important;
}

.orange.lighten-5, .unit-calltoaction-wrapper .orange.unit-enquire {
  background-color: #fff3e0 !important;
}

.orange-text.text-lighten-5 {
  color: #fff3e0 !important;
}

.orange.lighten-4 {
  background-color: #ffe0b2 !important;
}

.orange-text.text-lighten-4 {
  color: #ffe0b2 !important;
}

.orange.lighten-3, .orange.page-title, .orange.unit-calltoaction-wrapper {
  background-color: #ffcc80 !important;
}

.orange-text.text-lighten-3 {
  color: #ffcc80 !important;
}

.orange.lighten-2 {
  background-color: #ffb74d !important;
}

.orange-text.text-lighten-2 {
  color: #ffb74d !important;
}

.orange.lighten-1 {
  background-color: #ffa726 !important;
}

.orange-text.text-lighten-1 {
  color: #ffa726 !important;
}

.orange.darken-1 {
  background-color: #fb8c00 !important;
}

.orange-text.text-darken-1 {
  color: #fb8c00 !important;
}

.orange.darken-2 {
  background-color: #f57c00 !important;
}

.orange-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.orange-text {
  color: #f57c00 !important;
}

.orange.darken-3, .orange.page-footer {
  background-color: #ef6c00 !important;
}

.orange-text.text-darken-3 {
  color: #ef6c00 !important;
}

.orange.darken-4 {
  background-color: #e65100 !important;
}

.orange-text.text-darken-4 {
  color: #e65100 !important;
}

.orange.accent-1 {
  background-color: #ffd180 !important;
}

.orange-text.text-accent-1 {
  color: #ffd180 !important;
}

.orange.accent-2 {
  background-color: #ffab40 !important;
}

.orange-text.text-accent-2 {
  color: #ffab40 !important;
}

.orange.accent-3 {
  background-color: #ff9100 !important;
}

.orange-text.text-accent-3 {
  color: #ff9100 !important;
}

.orange.accent-4 {
  background-color: #ff6d00 !important;
}

.orange-text.text-accent-4 {
  color: #ff6d00 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
}

.deep-orange-text {
  color: #ff5722 !important;
}

.deep-orange.lighten-5, .unit-calltoaction-wrapper .deep-orange.unit-enquire {
  background-color: #fbe9e7 !important;
}

.deep-orange-text.text-lighten-5 {
  color: #fbe9e7 !important;
}

.deep-orange.lighten-4 {
  background-color: #ffccbc !important;
}

.deep-orange-text.text-lighten-4 {
  color: #ffccbc !important;
}

.deep-orange.lighten-3, .deep-orange.page-title, .deep-orange.unit-calltoaction-wrapper {
  background-color: #ffab91 !important;
}

.deep-orange-text.text-lighten-3 {
  color: #ffab91 !important;
}

.deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
}

.deep-orange-text.text-lighten-2 {
  color: #ff8a65 !important;
}

.deep-orange.lighten-1 {
  background-color: #ff7043 !important;
}

.deep-orange-text.text-lighten-1 {
  color: #ff7043 !important;
}

.deep-orange.darken-1 {
  background-color: #f4511e !important;
}

.deep-orange-text.text-darken-1 {
  color: #f4511e !important;
}

.deep-orange.darken-2 {
  background-color: #e64a19 !important;
}

.deep-orange-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.deep-orange-text {
  color: #e64a19 !important;
}

.deep-orange.darken-3, .deep-orange.page-footer {
  background-color: #d84315 !important;
}

.deep-orange-text.text-darken-3 {
  color: #d84315 !important;
}

.deep-orange.darken-4 {
  background-color: #bf360c !important;
}

.deep-orange-text.text-darken-4 {
  color: #bf360c !important;
}

.deep-orange.accent-1 {
  background-color: #ff9e80 !important;
}

.deep-orange-text.text-accent-1 {
  color: #ff9e80 !important;
}

.deep-orange.accent-2 {
  background-color: #ff6e40 !important;
}

.deep-orange-text.text-accent-2 {
  color: #ff6e40 !important;
}

.deep-orange.accent-3 {
  background-color: #ff3d00 !important;
}

.deep-orange-text.text-accent-3 {
  color: #ff3d00 !important;
}

.deep-orange.accent-4 {
  background-color: #dd2c00 !important;
}

.deep-orange-text.text-accent-4 {
  color: #dd2c00 !important;
}

.brown {
  background-color: #795548 !important;
}

.brown-text {
  color: #795548 !important;
}

.brown.lighten-5, .unit-calltoaction-wrapper .brown.unit-enquire {
  background-color: #efebe9 !important;
}

.brown-text.text-lighten-5 {
  color: #efebe9 !important;
}

.brown.lighten-4 {
  background-color: #d7ccc8 !important;
}

.brown-text.text-lighten-4 {
  color: #d7ccc8 !important;
}

.brown.lighten-3, .brown.page-title, .brown.unit-calltoaction-wrapper {
  background-color: #bcaaa4 !important;
}

.brown-text.text-lighten-3 {
  color: #bcaaa4 !important;
}

.brown.lighten-2 {
  background-color: #a1887f !important;
}

.brown-text.text-lighten-2 {
  color: #a1887f !important;
}

.brown.lighten-1 {
  background-color: #8d6e63 !important;
}

.brown-text.text-lighten-1 {
  color: #8d6e63 !important;
}

.brown.darken-1 {
  background-color: #6d4c41 !important;
}

.brown-text.text-darken-1 {
  color: #6d4c41 !important;
}

.brown.darken-2 {
  background-color: #5d4037 !important;
}

.brown-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.brown-text {
  color: #5d4037 !important;
}

.brown.darken-3, .brown.page-footer {
  background-color: #4e342e !important;
}

.brown-text.text-darken-3 {
  color: #4e342e !important;
}

.brown.darken-4 {
  background-color: #3e2723 !important;
}

.brown-text.text-darken-4 {
  color: #3e2723 !important;
}

.blue-grey {
  background-color: #607d8b !important;
}

.blue-grey-text {
  color: #607d8b !important;
}

.blue-grey.lighten-5, .unit-calltoaction-wrapper .blue-grey.unit-enquire {
  background-color: #eceff1 !important;
}

.blue-grey-text.text-lighten-5 {
  color: #eceff1 !important;
}

.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
}

.blue-grey-text.text-lighten-4 {
  color: #cfd8dc !important;
}

.blue-grey.lighten-3, .blue-grey.page-title, .blue-grey.unit-calltoaction-wrapper {
  background-color: #b0bec5 !important;
}

.blue-grey-text.text-lighten-3 {
  color: #b0bec5 !important;
}

.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
}

.blue-grey-text.text-lighten-2 {
  color: #90a4ae !important;
}

.blue-grey.lighten-1 {
  background-color: #78909c !important;
}

.blue-grey-text.text-lighten-1 {
  color: #78909c !important;
}

.blue-grey.darken-1 {
  background-color: #546e7a !important;
}

.blue-grey-text.text-darken-1 {
  color: #546e7a !important;
}

.blue-grey.darken-2 {
  background-color: #455a64 !important;
}

.blue-grey-text.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label.blue-grey-text {
  color: #455a64 !important;
}

.blue-grey.darken-3, .blue-grey.page-footer {
  background-color: #37474f !important;
}

.blue-grey-text.text-darken-3 {
  color: #37474f !important;
}

.blue-grey.darken-4 {
  background-color: #263238 !important;
}

.blue-grey-text.text-darken-4 {
  color: #263238 !important;
}

.grey, .page-footer, .page-title, .unit-calltoaction-wrapper, .unit-calltoaction-wrapper .unit-enquire {
  background-color: #9e9e9e !important;
}

.grey-text, nav a, .unit-calltoaction-wrapper .unit-calculate label, .unit-calltoaction-wrapper .unit-calculate .finance-disclaimer {
  color: #9e9e9e !important;
}

.grey.lighten-5, .lighten-5.page-footer, .lighten-5.page-title, .lighten-5.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .unit-enquire {
  background-color: #fafafa !important;
}

.grey-text.text-lighten-5, nav a.text-lighten-5, .unit-calltoaction-wrapper .unit-calculate label.text-lighten-5, .unit-calltoaction-wrapper .unit-calculate .text-lighten-5.finance-disclaimer {
  color: #fafafa !important;
}

.grey.lighten-4, .lighten-4.page-footer, .lighten-4.page-title, .lighten-4.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .lighten-4.unit-enquire {
  background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4, nav a.text-lighten-4, .unit-calltoaction-wrapper .unit-calculate label.text-lighten-4, .unit-calltoaction-wrapper .unit-calculate .text-lighten-4.finance-disclaimer {
  color: #f5f5f5 !important;
}

.grey.lighten-3, .lighten-3.page-footer, .page-title, .unit-calltoaction-wrapper, .unit-calltoaction-wrapper .lighten-3.unit-enquire, .unit-calltoaction-wrapper .unit-enquire.page-title, .unit-calltoaction-wrapper .unit-enquire.unit-calltoaction-wrapper {
  background-color: #eeeeee !important;
}

.grey-text.text-lighten-3, nav a.text-lighten-3, .unit-calltoaction-wrapper .unit-calculate label.text-lighten-3, .unit-calltoaction-wrapper .unit-calculate .text-lighten-3.finance-disclaimer {
  color: #eeeeee !important;
}

.grey.lighten-2, .lighten-2.page-footer, .lighten-2.page-title, .lighten-2.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .lighten-2.unit-enquire {
  background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2, nav a.text-lighten-2, .unit-calltoaction-wrapper .unit-calculate label.text-lighten-2, .unit-calltoaction-wrapper .unit-calculate .text-lighten-2.finance-disclaimer {
  color: #e0e0e0 !important;
}

.grey.lighten-1, .lighten-1.page-footer, .lighten-1.page-title, .lighten-1.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .lighten-1.unit-enquire {
  background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1, nav a.text-lighten-1, .unit-calltoaction-wrapper .unit-calculate label.text-lighten-1, .unit-calltoaction-wrapper .unit-calculate .text-lighten-1.finance-disclaimer {
  color: #bdbdbd !important;
}

.grey.darken-1, .darken-1.page-footer, .darken-1.page-title, .darken-1.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .darken-1.unit-enquire {
  background-color: #757575 !important;
}

.grey-text.text-darken-1, nav a.text-darken-1, .unit-calltoaction-wrapper .unit-calculate label.text-darken-1, .unit-calltoaction-wrapper .unit-calculate .text-darken-1.finance-disclaimer {
  color: #757575 !important;
}

.grey.darken-2, .darken-2.page-footer, .darken-2.page-title, .darken-2.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .darken-2.unit-enquire {
  background-color: #616161 !important;
}

.grey-text.text-darken-2, nav a.text-darken-2, .unit-calltoaction-wrapper .unit-calculate label, .unit-calltoaction-wrapper .unit-calculate .text-darken-2.finance-disclaimer, .unit-calltoaction-wrapper .unit-calculate label.finance-disclaimer {
  color: #616161 !important;
}

.grey.darken-3, .page-footer, .darken-3.page-title, .darken-3.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .darken-3.unit-enquire, .unit-calltoaction-wrapper .unit-enquire.page-footer {
  background-color: #424242 !important;
}

.grey-text.text-darken-3, nav a.text-darken-3, .unit-calltoaction-wrapper .unit-calculate label.text-darken-3, .unit-calltoaction-wrapper .unit-calculate .text-darken-3.finance-disclaimer {
  color: #424242 !important;
}

.grey.darken-4, .darken-4.page-footer, .darken-4.page-title, .darken-4.unit-calltoaction-wrapper, .unit-calltoaction-wrapper .darken-4.unit-enquire {
  background-color: #212121 !important;
}

.grey-text.text-darken-4, nav a.text-darken-4, .unit-calltoaction-wrapper .unit-calculate label.text-darken-4, .unit-calltoaction-wrapper .unit-calculate .text-darken-4.finance-disclaimer {
  color: #212121 !important;
}

.black {
  background-color: #000000 !important;
}

.black-text {
  color: #000000 !important;
}

.white {
  background-color: #FFFFFF !important;
}

.white-text {
  color: #FFFFFF !important;
}

.transparent {
  background-color: transparent !important;
}

.transparent-text {
  color: transparent !important;
}

.tabs, .contextual-tabs {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 48px;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  white-space: nowrap;
}

.tabs.tabs-transparent, .tabs-transparent.contextual-tabs {
  background-color: transparent;
}

.tabs.tabs-transparent .tab a, .tabs-transparent.contextual-tabs .tab a,
.tabs.tabs-transparent .tab.disabled a,
.tabs-transparent.contextual-tabs .tab.disabled a,
.tabs.tabs-transparent .tab.disabled a:hover,
.tabs-transparent.contextual-tabs .tab.disabled a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.tabs.tabs-transparent .tab a:hover, .tabs-transparent.contextual-tabs .tab a:hover,
.tabs.tabs-transparent .tab a.active,
.tabs-transparent.contextual-tabs .tab a.active {
  color: #fff;
}

.tabs.tabs-transparent .indicator, .tabs-transparent.contextual-tabs .indicator {
  background-color: #fff;
}

.tabs.tabs-fixed-width, .tabs-fixed-width.contextual-tabs {
  display: flex;
}

.tabs.tabs-fixed-width .tab, .tabs-fixed-width.contextual-tabs .tab {
  flex-grow: 1;
}

.tabs .tab, .contextual-tabs .tab {
  display: inline-block;
  text-align: center;
  line-height: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.tabs .tab a, .contextual-tabs .tab a {
  color: rgba(120, 144, 156, 0.7);
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color .28s ease;
}

.tabs .tab a:hover, .contextual-tabs .tab a:hover, .tabs .tab a.active, .contextual-tabs .tab a.active {
  background-color: transparent;
  color: #78909c;
}

.tabs .tab.disabled a, .contextual-tabs .tab.disabled a,
.tabs .tab.disabled a:hover,
.contextual-tabs .tab.disabled a:hover {
  color: rgba(120, 144, 156, 0.7);
  cursor: default;
}

.tabs .indicator, .contextual-tabs .indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #a4b4bc;
  will-change: left, right;
}

@media only screen and (max-width: 992px) {
  .tabs, .contextual-tabs {
    display: flex;
  }
  .tabs .tab, .contextual-tabs .tab {
    flex-grow: 1;
  }
  .tabs .tab a, .contextual-tabs .tab a {
    padding: 0 12px;
  }
}

.entity-brand.brand-icon img {
  width: 100%;
  height: auto;
}

.view-brands.view-display-id-block .view-content {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.view-brands.view-display-id-block .view-content .views-row {
  max-width: 120px;
  margin: 0 1rem 1rem 1rem;
}

.card .card-image img {
  height: auto;
}

.card .card-title {
  font-size: 1.25rem;
  font-weight: 400;
}

.card .card-title-suffix {
  font-size: 1rem;
  color: rgba(128, 128, 128, 0.87);
}

.card .card-content {
  position: relative;
  min-height: 80px;
}

.card .card-content .card-title {
  font-size: 1.25rem;
  line-height: 1.3;
}

.card .card-reveal .card-title {
  line-height: 1.2;
}

.card .card-reveal p {
  margin-top: 8px;
}

.card .card-reveal .view-display-id-block_1 ul {
  margin: 0.75em 0;
}

.card .card-reveal .view-display-id-block_1 ul li {
  margin: 0 0 0.25em 0;
}

.card .card-reveal .view-display-id-block_1 ul li a {
  color: #78909c;
}

.card .card-reveal .menu-list-learner,
.card .card-reveal .menu-list-pricing {
  margin-left: .5rem;
}

.card .card-reveal .menu-list-learner svg,
.card .card-reveal .menu-list-pricing svg {
  height: 16px;
  margin-bottom: -3px;
}

.card .card-reveal .menu-list-learner svg {
  fill: #fbc02d;
}

.card .card-reveal .menu-list-pricing svg {
  fill: #D50000;
}

.card .card-action {
  text-align: center;
}

.card .card-action a {
  margin-right: 0 !important;
  color: #78909c !important;
}

.unit-card-wrapper {
  position: relative;
}

.unit-card-wrapper .btn-floating.halfway-fab {
  bottom: 36px;
}

.node-unit .unit-flags {
  position: absolute;
  top: 18px;
  right: 16px;
}

.node-unit .unit-flags .learner-flag svg {
  height: 16px;
  fill: #fbc02d;
  margin-bottom: -3px;
}

.node-unit .unit-flags .finance-flag {
  margin-left: 5px;
  color: #D50000;
}

.node-unit .unit-flags .finance-flag svg {
  height: 16px;
  fill: #D50000;
  margin-bottom: -3px;
}

.node-unit .card-content {
  text-align: center;
}

.node-unit .card-content .card-title {
  margin-bottom: 0;
}

.node-unit .card-content .card-title.truncate:not(:last-child) {
  margin-left: 20px;
  margin-right: 20px;
}

.view-display-id-panel_pane_1 .card {
  box-shadow: none;
}

.view-display-id-panel_pane_1 .card .card-content {
  background: rgba(160, 160, 160, 0.2);
}

.view-display-id-panel_pane_2 .card {
  box-shadow: none;
}

.card-carousel,
.cvp-carousel {
  margin: 0 -20px;
  width: calc(100% + 40px);
  overflow: hidden;
}

#nav-contact li {
  line-height: 1.1;
}

#nav-contact li > a {
  min-height: 35px;
  height: auto;
  line-height: 1.5;
  padding-left: 2rem;
  margin-left: 2rem;
  position: relative;
}

#nav-contact li > a > i {
  margin: 0;
  position: absolute;
  left: 0;
  top: -2px;
  line-height: 1;
}

#nav-contact li > a:hover {
  background-color: transparent;
}

#nav-contact a.header {
  font-size: 1.5rem;
  line-height: 1;
  padding-left: 0;
  margin-top: 2rem;
  margin-bottom: .5rem;
  height: auto;
  cursor: default;
}

#nav-contact .sidebar-contact-form {
  margin: 0 2rem 2rem 2rem;
}

#nav-contact .sidebar-contact-form h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0 2rem 0;
}

#nav-contact .sidebar-contact-form .form-item {
  margin: 0 0 1rem 0;
}

#nav-contact .sidebar-contact-form textarea {
  margin-bottom: 0;
}

#nav-contact .sidebar-contact-form label {
  font-size: .9rem;
  font-weight: 500;
}

#nav-contact .sidebar-contact-form button {
  margin-top: 1rem;
}

#nav-contact .contacts-social {
  margin-left: 2rem;
  display: block;
  margin-bottom: 1rem;
}

#nav-contact .contacts-social a {
  color: black;
  margin-right: 1rem;
  font-size: 24px;
}

.page-footer #brands {
  margin: 3rem 0 1rem;
}

.page-footer .footer-menu {
  margin: 1rem 0;
  font-weight: 200;
}

.page-footer .footer-menu a:after {
  content: '/';
  margin: 0 1rem;
}

.page-footer .footer-menu a:last-child:after {
  content: '';
  margin: 0;
}

.page-footer .footer-copyright {
  background-color: rgba(0, 0, 0, 0.25);
}

.page-footer p, .page-footer li {
  font-weight: 300;
  font-size: .9rem;
}

.page-footer .contacat-list li {
  margin-bottom: .5rem;
  position: relative;
  padding-left: 23px;
}

.page-footer .contacat-list li .material-icons {
  position: absolute;
  top: 3px;
  left: 0;
}

.page-footer .contacts-social a {
  margin-right: .75rem;
}

.page-footer a {
  color: white;
}

.container-inline .form-type-radios > label {
  display: block;
  margin-bottom: 10px;
}

.container-inline .form-type-radios .form-radios {
  display: block;
  width: 100%;
}

[type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
  padding-left: 25px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 601px) {
  .motordealer .container {
    width: 90%;
  }
}

@media only screen and (min-width: 993px) {
  .motordealer .container {
    width: 85%;
  }
}

@media only screen and (min-width: 1401px) {
  .motordealer .container {
    width: 70%;
  }
}

.dropdown-mega {
  left: 0 !important;
  width: 100%;
}

.dropdown-mega .container {
  display: flex;
  justify-content: space-between;
}

.dropdown-mega li {
  clear: none;
  width: auto;
}

.dropdown-mega li a {
  text-align: center;
}

.dropdown-mega li a .responsive-img {
  width: 120px;
}

.dropdown-mega li a h3 {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 0 .5rem 0;
}

.dropdown-mega li a .view-display-id-block_3 {
  overflow: hidden;
  height: 126px;
}

.dropdown-mega li a .view-display-id-block_3 .view-content {
  transition: margin 300ms;
}

.dropdown-mega li a:hover {
  background-color: transparent;
}

.dropdown-mega li a:hover .view-display-id-block_3 .view-content {
  margin-top: -5px;
}

.dropdown-mega li:hover {
  background-color: transparent;
}

#nav-mobile.side-nav > li > a {
  padding: 0 1rem;
}

#nav-mobile.side-nav li > a i {
  margin: 0;
}

.modal {
  max-height: 90%;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav {
  background: white;
  box-shadow: 0 2px 2px 0 rgba(158, 158, 158, 0.14), 0 1px 5px 0 rgba(158, 158, 158, 0.12), 0 3px 1px -2px rgba(158, 158, 158, 0.2);
}

nav a.active, nav a:hover {
  color: black !important;
}

nav a.active {
  background: rgba(255, 255, 255, 0.1);
}

nav a.button-collapse {
  color: black !important;
}

nav ul > li > a {
  text-transform: uppercase;
  font-weight: 500;
}

nav ul.desktop-menu > li > a {
  display: flex;
}

nav .brand-logo {
  padding: .75rem .75rem .75rem 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

nav .brand-logo img {
  max-height: 100%;
  width: auto;
}

#header,
#navigation {
  height: 64px;
  line-height: 64px;
}

#navigation {
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
  top: 0;
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

@media only screen and (min-width: 601px) {
  #header,
  #navigation {
    height: 128px;
    line-height: 128px;
  }
  #header .nav-wrapper i,
  #navigation .nav-wrapper i {
    height: 128px;
    line-height: 128px;
    margin-left: .25rem;
  }
}

body.admin-menu #navigation {
  top: auto;
}

.page-title {
  padding: 2rem 0;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
}

.page-title h1 {
  position: relative;
}

.page-title:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  opacity: 0.1;
}

.entity-pricing.chevron {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  width: 150px;
  margin: 0 1rem 1rem 0;
  float: left;
  z-index: 90;
}

.entity-pricing.chevron .chevron-title {
  color: white;
  background-color: #616161;
  font-size: 14px;
  line-height: 14px;
  padding: 8px 16px;
}

.entity-pricing.chevron .chevron-cta {
  color: #FF8A80;
  background-color: #D50000;
  font-size: 20px;
  line-height: 20px;
  border-top: 3px solid #e0e0e0;
  padding-top: 0.5rem;
}

.entity-pricing.chevron .chevron-ppw-wrapper {
  color: white;
  padding-top: 5px;
  background-color: #D50000;
}

.entity-pricing.chevron .chevron-ppw-wrapper .chevron-price-pw-details {
  font-size: 10px;
  margin-bottom: 5px;
}

.entity-pricing.chevron .chevron-ppw-wrapper .chevron-price-pw-details:last-child {
  margin-bottom: 0;
}

.entity-pricing.chevron .chevron-ppw-wrapper .chevron-price-pw,
.entity-pricing.chevron .chevron-ppw-wrapper .chevron-comparison-rate,
.entity-pricing.chevron .chevron-ppw-wrapper .chevron-price-otd {
  font-size: 25px;
  line-height: 25px;
}

.entity-pricing.chevron .chevron-ppw-wrapper .ast {
  font-size: 10px;
  line-height: 8px;
  vertical-align: super;
}

.entity-pricing.chevron .chevron-base svg {
  fill: #D50000;
}

.entity-pricing.chevron:hover .chevron-cta {
  color: white;
}

.pricing-disclaimer {
  font-size: .8rem;
  color: #bdbdbd;
}

.unit-calltoaction-wrapper {
  margin-top: 4rem;
}

.unit-calltoaction-wrapper .unit-enquire {
  padding: 2rem;
}

.unit-calltoaction-wrapper .unit-calculate {
  padding: 2rem;
}

.unit-calltoaction-wrapper .unit-calculate .repayment-title,
.unit-calltoaction-wrapper .unit-calculate .repayments,
.unit-calltoaction-wrapper .unit-calculate .repayment-frequency {
  display: block;
}

.unit-calltoaction-wrapper .unit-calculate .repayment-title {
  margin-top: 1rem;
}

.unit-calltoaction-wrapper .unit-calculate .repayments {
  font-size: 2.75rem;
  line-height: 1.1;
  font-weight: 500;
}

.unit-calltoaction-wrapper .unit-calculate .md-finance-apply {
  margin: 1rem 0;
}

.unit-calltoaction-wrapper .unit-calculate .finance-disclaimer {
  font-size: .8rem;
}

.unit-calltoaction-wrapper .row {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .unit-calltoaction-wrapper .unit-enquire,
  .unit-calltoaction-wrapper .unit-calculate {
    padding: 1rem 1rem 2rem 1rem;
  }
}

.unit-header-boilerplate-wrapper {
  background: white;
}

.unit-header-boilerplate-wrapper h1,
.unit-header-boilerplate-wrapper .row {
  margin-bottom: 0;
}

.unit-header-boilerplate-wrapper .unit-boilerplate-learner svg {
  height: 16px;
  fill: #fbc02d;
  margin-bottom: -3px;
}

.unit-header-boilerplate-wrapper .unit-boilerplate-finance {
  color: #D50000;
}

.unit-header-boilerplate-wrapper .unit-boilerplate-finance svg {
  height: 16px;
  fill: #D50000;
  margin-bottom: -3px;
}

@media only screen and (max-width: 600px) {
  .unit-overview img {
    object-fit: cover;
    height: 225px;
  }
  .title-column {
    margin-bottom: 2rem;
  }
  .title-column .unit-title {
    margin-top: 0;
  }
}

@media only screen and (min-width: 601px) {
  .unit-header-boilerplate-wrapper .row {
    display: flex;
  }
  .unit-header-boilerplate-wrapper .row .title-column {
    display: flex;
    align-items: center;
  }
}

.tablefield tbody {
  border-top: none;
}

.tablefield tr {
  background-color: transparent;
  border-bottom-color: #eeeeee;
}

.tablefield tr td:first-of-type {
  width: 1%;
  white-space: nowrap;
  font-weight: 600;
  padding-right: 1rem;
}

.tablefield ul {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .tablefield tbody tr td {
    width: 80%;
  }
  .tablefield tbody tr td:first-of-type {
    width: 20%;
    white-space: normal;
  }
}

.slider .indicators .indicator-item {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #D6D6D6;
  bottom: 50px;
  z-index: 5;
}

.slider .indicators .indicator-item.active {
  background-color: #869791;
}

.slider-wrapper {
  height: 400px;
  width: 100%;
  margin-bottom: 4rem;
}

.md-slideshow .owl-item {
  opacity: .33;
}

.md-slideshow .owl-item.center {
  opacity: 1;
}

.md-slideshow .owl-item .responsive-img {
  width: 100vw;
}

@media only screen and (min-width: 993px) {
  .md-slideshow .owl-item .responsive-img {
    width: 85vw;
  }
}

@media only screen and (min-width: 1401px) {
  .md-slideshow .owl-item .responsive-img {
    width: 70vw;
  }
}

.md-slideshow .owl-nav.disabled {
  display: block;
}

.md-slideshow .owl-prev,
.md-slideshow .owl-next {
  position: absolute;
  top: 37%;
  font-size: 3rem !important;
  line-height: .6 !important;
  background: none !important;
}

.md-slideshow .owl-prev:hover,
.md-slideshow .owl-next:hover {
  background: none !important;
}

@media only screen and (min-width: 993px) {
  .md-slideshow .owl-prev,
  .md-slideshow .owl-next {
    top: 47%;
  }
}

.md-slideshow .owl-prev {
  left: 10px;
}

@media only screen and (min-width: 993px) {
  .md-slideshow .owl-prev {
    left: 8%;
  }
}

@media only screen and (min-width: 1401px) {
  .md-slideshow .owl-prev {
    left: 16%;
  }
}

.md-slideshow .owl-next {
  right: 10px;
}

@media only screen and (min-width: 993px) {
  .md-slideshow .owl-next {
    right: 8%;
  }
}

@media only screen and (min-width: 1401px) {
  .md-slideshow .owl-next {
    right: 16%;
  }
}

.yamaha-dealer-cta {
  text-align: center;
  padding: 2rem;
}

.yamaha-dealer-cta svg {
  height: 150px;
  width: auto;
}

@media only screen and (min-width: 601px) {
  .yamaha-dealer-cta svg {
    height: 100px;
  }
}

@media only screen and (min-width: 993px) {
  .yamaha-dealer-cta svg {
    height: 75px;
  }
}

.yamaha-dealer-cta a {
  color: rgba(0, 0, 0, 0.87);
}

.yamaha-dealer-cta a:hover {
  color: #c00;
}

.yamaha-dealer-cta a:hover svg {
  fill: #c00;
}

.yamaha-dealer-cta p {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Roboto, sans-serif;
  font-size: 2rem;
}

@media only screen and (min-width: 601px) {
  .yamaha-dealer-cta p {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 993px) {
  .yamaha-dealer-cta p {
    font-size: 1rem;
  }
}

.panels-flexible-region-new-center,
.panels-flexible-region-1-center {
  float: none;
  width: 100%;
}

.panel-flexible .panel-separator {
  margin: 0;
}

body.front .pane-news-news-panel-pane-1 {
  margin: 2rem auto;
}

body.front .pane-news-news-panel-pane-1 .pane-title {
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
}

body.front .pane-news-news-panel-pane-1 .more-link {
  text-align: center;
  width: 100%;
}

body.front .yamaha-dealer-cta-wrapper {
  background: rgba(242, 242, 242, 0.87);
}

.pane-product-pargraphs-panel-pane-1,
.pane-node-field-specification-table,
.pane-product-pargraphs-panel-pane-2,
.container.chevrons {
  margin-top: 4rem;
}

.page-products-yamaha .page-title {
  background-color: black !important;
  padding-bottom: 1rem;
  height: 200px;
}

@media only screen and (min-width: 993px) {
  .page-products-yamaha .page-title {
    height: 400px;
  }
}

.page-products-yamaha .page-title:before {
  filter: none;
  opacity: 1;
  -webkit-mask-image: linear-gradient(black 66%, transparent);
}

.page-products-yamaha .page-title > .container {
  display: flex;
  height: 100%;
}

.page-products-yamaha .page-title > .container > h1 {
  align-self: flex-end;
  color: white;
  text-shadow: 0 3px 7px black;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0;
}

.page-products-yamaha .page-title > .container > h1 > span {
  font-size: 15px;
  color: #78909c;
  line-height: 1;
  display: block;
}

.node-type-promotion .banner-image .field-name-field-image img {
  width: 100%;
}

.contextual-tabs .tab {
  border-bottom: 3px solid #e0e0e0;
}

.contextual-tabs .tab.is-active {
  border-bottom: 3px solid #78909c;
}

h1 {
  font-weight: 500;
}

.pane-image {
  max-width: 400px;
  margin-bottom: 32px;
}

.owl-carousel {
  z-index: 2;
}

.hoverable:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (min-width: 601px) {
  .pane-image.left {
    margin-right: 32px;
  }
  .pane-image.right {
    margin-left: 32px;
  }
}

body.admin-menu.adminimal-menu:before {
  content: none;
}
