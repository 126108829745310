.page-footer {
  @extend .grey, .darken-3 !optional;

  #brands {
    margin: 3rem 0 1rem;
  }

  .footer-menu {
    margin: 1rem 0;
    font-weight: 200;

    a {
      &:after {
        content: '/';
        margin: 0 1rem;
      }
      &:last-child {
        &:after {
          content: '';
          margin: 0;
        }
      }
    }
  }

  .footer-copyright {
    background-color: rgba(0,0,0,.25);
  }

  p, li {
    font-weight: 300;
    font-size: .9rem;
  }

  .contacat-list {
    li {
      margin-bottom: .5rem;
      position: relative;
      padding-left: 23px;

      .material-icons {
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }

  .contacts-social {
    a {
      margin-right: .75rem;
    }
  }

  a {
    color: white;
  }
}
