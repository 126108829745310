.unit-header-boilerplate-wrapper {
  background: white;

  h1,
  .row {
    margin-bottom: 0;
  }

  .unit-boilerplate-learner {
    svg {
      height: 16px;
      fill: color('yellow', 'darken-2');
      margin-bottom: -3px;
    }
  }

  .unit-boilerplate-finance {
    color: color('red', 'accent-4');
    svg {
      height: 16px;
      fill: color('red', 'accent-4');
      margin-bottom: -3px;
    }
  }
}

@media #{$small-and-down} {
  .unit-overview {
    img {
      object-fit: cover;
      height: 225px;
    }
  }

  .title-column {
    margin-bottom: 2rem;

    .unit-title {
      margin-top: 0;
    }
  }
}

@media #{$medium-and-up} {
  .unit-header-boilerplate-wrapper {
    .row {
      display: flex;

      .title-column {
        display: flex;
        align-items: center;
      }
    }
  }
}
