.tablefield {
  tbody {
    border-top: none;
  }

  tr {
    background-color: transparent;
    border-bottom-color: color('grey', 'lighten-3');

    td {
      &:first-of-type {
        width: 1%;
        white-space: nowrap;
        font-weight: 600;
        padding-right: 1rem;
      }
    }
  }

  ul {
    margin: 0;
  }
}

@media #{$small-and-down} {
  .tablefield {
    tbody {
      tr {
        td {
          width: 80%;

          &:first-of-type {
            width: 20%;
            white-space: normal;
          }
        }
      }
    }
  }
}
