.container-inline {
  .form-type-radios {
    > label {
      display: block;
      margin-bottom: 10px;
    }
    .form-radios {
      display: block;
      width: 100%;
    }
  }
}

[type="radio"] {
  &:not(:checked),
  &:checked {
    + label {
      padding-left: 25px;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
