#nav-mobile {
  &.side-nav {
    > li > a {
      padding: 0 1rem;
    }

    li > a {
      i {
        margin: 0;
      }
    }
  }
}
