$yamaha-red: #c00;

.yamaha-dealer-cta {
  text-align: center;
  padding: 2rem;

  svg {
    height: 150px;
    width: auto;

    @media #{$medium-and-up} {
      height: 100px;
    }

    @media #{$large-and-up} {
      height: 75px;
    }
  }

  a {
    color: $off-black;

    &:hover {
      color: $yamaha-red;

      svg {
        fill: $yamaha-red;
      }
    }
  }

  p {
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Roboto, sans-serif;
    font-size: 2rem;

    @media #{$medium-and-up} {
      font-size: 1.5rem;
    }

    @media #{$large-and-up} {
      font-size: 1rem;
    }
  }
}
