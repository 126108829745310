.motordealer {
  @media #{$medium-and-up} {
    .container {
      width: 90%;
    }
  }
  @media #{$large-and-up} {
    .container {
      width: 85%;
    }
  }
  @media #{$extra-extra-large-and-up} {
    .container {
      width: 70%;
    }
  }
}
