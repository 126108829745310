.entity-pricing {
  &.chevron {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    width: 150px;
    margin: 0 1rem 1rem 0;
    float: left;
    z-index: 90;

    .chevron-title {
      color: white;
      background-color: color('grey', 'darken-2');
      font-size: 14px;
      line-height: 14px;
      padding: 8px 16px;
    }

    .chevron-cta {
      color: color('red', 'accent-1');
      background-color: color('red', 'accent-4');
      font-size: 20px;
      line-height: 20px;
      border-top: 3px solid color('grey', 'lighten-2');
      padding-top: 0.5rem;
    }

    .chevron-ppw-wrapper {
      color: white;
      padding-top: 5px;
      background-color: color('red', 'accent-4');

      .chevron-price-pw-details {
        font-size: 10px;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .chevron-price-pw,
      .chevron-comparison-rate,
      .chevron-price-otd {
        font-size: 25px;
        line-height: 25px;
      }

      .ast {
        font-size: 10px;
        line-height: 8px;
        vertical-align: super;
      }
    }

    .chevron-base {
      svg {
        fill: color('red', 'accent-4');
      }
    }

    &:hover {
      .chevron-cta {
        color: white;
      }
    }
  }
}

.pricing-disclaimer {
  font-size: .8rem;
  color: color('grey', 'lighten-1');
}
