#nav-contact {
  li {
    line-height: 1.1;

    > a {
      min-height: 35px;
      height: auto;
      line-height: 1.5;
      padding-left: 2rem;
      margin-left: 2rem;
      position: relative;

      > i {
        margin: 0;
        position: absolute;
        left: 0;
        top: -2px;
        line-height: 1;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  a.header {
    font-size: 1.5rem;
    line-height: 1;
    padding-left: 0;
    margin-top: 2rem;
    margin-bottom: .5rem;
    height: auto;
    cursor: default;
  }

  .sidebar-contact-form {
    margin: 0 2rem 2rem 2rem;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      margin: 1rem 0 2rem 0;
    }

    .form-item {
      margin: 0 0 1rem 0;
    }

    textarea {
      margin-bottom: 0;
    }

    label {
      font-size: .9rem;
      font-weight: 500;
    }

    button {
      margin-top: 1rem;
    }
  }

  .contacts-social {
    margin-left: 2rem;
    display: block;
    margin-bottom: 1rem;

    a {
      color: black;
      margin-right: 1rem;
      font-size: 24px;
    }
  }
}
