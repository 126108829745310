.page-title {
  @extend .grey, .lighten-3 !optional;
  padding: 2rem 0;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;

  h1 {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
    opacity: 0.1;
  }
}
