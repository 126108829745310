.entity-brand {
  &.brand-icon  {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.view-brands {
  &.view-display-id-block {
    .view-content {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;

      .views-row {
        max-width: 120px;
        margin: 0 1rem 1rem 1rem;
      }
    }
  }
}
