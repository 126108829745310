.pane-product-pargraphs-panel-pane-1,
.pane-node-field-specification-table,
.pane-product-pargraphs-panel-pane-2,
.container.chevrons {
  margin-top: 4rem;
}

.page-products-yamaha {
  .page-title {
    background-color: black !important;
    padding-bottom: 1rem;
    height: 200px;

    @media #{$large-and-up} {
      height: 400px;
    }

    &:before {
      filter: none;
      opacity: 1;
      -webkit-mask-image: linear-gradient(black 66%, transparent);
    }

    > .container {
      display: flex;
      height: 100%;

      > h1 {
        align-self: flex-end;
        color: white;
        text-shadow: 0 3px 7px black;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 0;

        > span {
          font-size: 15px;
          color: $primary-color;
          line-height: 1;
          display: block;
        }
      }
    }
  }
}
