.md-slideshow {
  .owl-item {
    opacity: .33;

    &.center {
      opacity: 1;
    }

    .responsive-img {
      width: 100vw;

      @media #{$large-and-up} {
        width: 85vw;
      }

      @media #{$extra-extra-large-and-up} {
        width: 70vw;
      }
    }
  }

  .owl-nav {
    &.disabled {
      display: block;
    }
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 37%;
    font-size: 3rem !important;
    line-height: .6 !important;
    background: none !important;

    &:hover {
      background: none !important;
      ;
    }

    @media #{$large-and-up} {
      top: 47%;
    }
  }

  .owl-prev {
    left: 10px;

    @media #{$large-and-up} {
      left: 8%;
    }

    @media #{$extra-extra-large-and-up} {
      left: 16%;
    }
  }

  .owl-next {
    right: 10px;

    @media #{$large-and-up} {
      right: 8%;
    }

    @media #{$extra-extra-large-and-up} {
      right: 16%;
    }
  }
}
