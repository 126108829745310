body.front {
  .pane-news-news-panel-pane-1 {
    margin: 2rem auto;

    .pane-title {
      font-weight: bold;
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    .more-link {
      text-align: center;
      width: 100%;
    }
  }
  .yamaha-dealer-cta-wrapper {
    background: lighten($off-black, 95%);
  }
}
