.unit-calltoaction-wrapper {
  margin-top: 4rem;
  @extend .grey, .lighten-3 !optional;
  .unit-enquire {
    @extend .grey, .lighten-5 !optional;
    padding: 2rem;
  }
  .unit-calculate {
    padding: 2rem;
    label {
      @extend .grey-text, .text-darken-2 !optional;
    }
    .repayment-title,
    .repayments,
    .repayment-frequency {
      display: block;
    }
    .repayment-title {
      margin-top: 1rem;
    }
    .repayments {
      font-size: 2.75rem;
      line-height: 1.1;
      font-weight: 500;
    }
    .md-finance-apply {
      margin: 1rem 0;
    }
    .finance-disclaimer {
      font-size: .8rem;
      @extend .grey-text !optional;
    }
  }
  .row {
    margin-bottom: 0;
  }
}

@media #{$small-and-down} {
  .unit-calltoaction-wrapper {
    .unit-enquire,
    .unit-calculate {
      padding: 1rem 1rem 2rem 1rem;
    }
  }
}
