h1 {
  font-weight: 500;
}

.pane-image {
  max-width: 400px;
  margin-bottom: 32px;
}

.owl-carousel {
  z-index: 2;
}

.hoverable {
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }
}

@media #{$medium-and-up} {
  .pane-image {
    &.left {
      margin-right: 32px;
    }

    &.right {
      margin-left: 32px;
    }
  }
}

// Remove admin menu body padding.
body.admin-menu.adminimal-menu:before {
  content: none;
}
