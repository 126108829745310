.contextual-tabs {
  @extend .tabs !optional;

  .tab {
    border-bottom: 3px solid color('grey', 'lighten-2');

    &.is-active {
      border-bottom: 3px solid color('blue-grey', 'lighten-1');
    }
  }
}
