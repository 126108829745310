.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav {
  background: white;
  box-shadow: 0 2px 2px 0 rgba(158,158,158,0.14), 0 1px 5px 0 rgba(158,158,158,0.12), 0 3px 1px -2px rgba(158,158,158,0.2);

  a {
    @extend .grey-text !optional;

    &.active,
    &:hover {
      color: black !important;
    }

    &.active {
      background: rgba(255,255,255,.1);
    }

    &.button-collapse {
      color: black !important;
    }
  }

  ul {
    > li {
      > a {
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    &.desktop-menu {
      > li {
        > a {
          display: flex;
        }
      }
    }
  }

  .brand-logo {
    padding: .75rem .75rem .75rem 0;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
      width: auto;
    }
  }
}

#header,
#navigation {
  height: 64px;
  line-height: 64px;
}

#navigation {
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
  top: 0;
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

@media #{$medium-and-up} {
  #header,
  #navigation {
    height: 128px;
    line-height: 128px;

    .nav-wrapper {
      i {
        height: 128px;
        line-height: 128px;
        margin-left: .25rem;
      }
    }
  }
}

body.admin-menu {
  #navigation {
    top: auto;
  }
}
