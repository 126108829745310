.slider {
  .indicators {
    .indicator-item {
      height: 10px;
      width: 10px;
      margin: 0 5px;
      background-color: #D6D6D6;
      bottom: 50px;
      z-index: 5;

      &.active {
        background-color: #869791;
      }
    }
  }
}
.slider-wrapper {
  height: 400px;
  width: 100%;
  margin-bottom: 4rem;
}
