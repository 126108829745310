.dropdown-mega {
  left: 0 !important;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
  }

  li {
    clear: none;
    width: auto;

    a {
      text-align: center;

      .responsive-img {
        width: 120px;
      }

      h3 {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0 0 .5rem 0;
      }

      .view-display-id-block_3 {
        overflow: hidden;
        height: 126px;

        .view-content {
          transition: margin 300ms;
        }
      }

      &:hover {
        background-color: transparent;

        .view-display-id-block_3 {
          .view-content {
            margin-top: -5px;
          }
        }
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}
